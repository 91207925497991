import { SelectStoreComponent } from './components/selects/select-store/select-store.component';
import { AuthGuardService } from "./services/auth-guard.service";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ApiModule } from "../api/api.module";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LoadingModule } from "./loading/loading.module";
import { IonicStorageModule } from "@ionic/storage";
import { StateService } from "./state/state.service";
import { PopoverComponent } from "./popover/popover.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { TaskDetailComponent } from "./components/tasks/task-detail/task-detail.component";

import { AngularImageViewerModule } from "angular-x-image-viewer";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { File } from '@ionic-native/file/ngx';

// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

@NgModule({
  declarations: [
    AppComponent,
    PopoverComponent,
    TaskDetailComponent
  ],
  entryComponents: [PopoverComponent, TaskDetailComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, "assets/i18n/", ".json"),
        deps: [HttpClient],
      },
    }),
    IonicStorageModule.forRoot(),
    ApiModule,
    LoadingModule,
    ReactiveFormsModule,
    FormsModule,
    AngularImageViewerModule,
    FontAwesomeModule,
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    StateService,
    AuthGuardService,
    File
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
