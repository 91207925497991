import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as Url from 'url';
import {throwError} from 'rxjs';

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const url = Url.parse(req.url);
        if (url.protocol === 'http:') {
            if (!navigator.onLine) {
                return throwError('OFFLINE');
            }
        }
        return next.handle(req);
    }
}
