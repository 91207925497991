import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiService} from './api.service';
import {HttpClientModule} from '@angular/common/http';
import {httpInterceptorProviders} from './http-interceptors';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        ApiService,
        httpInterceptorProviders
    ]
})
export class ApiModule {
}
