export class UrlEncodedString {

    body = '';

    public append(name, value) {
      if (this.body === '') {
        this.body = encodeURIComponent(name) + '=' + encodeURIComponent(value);
      } else {
        this.body = this.body + '&' + encodeURIComponent(name) + '=' + encodeURIComponent(value);
      }
    }

    public appendS(name) {
      if (this.body === '') {
        this.body = encodeURIComponent(name);
      } else {
        this.body = this.body + '&' + encodeURIComponent(name);
      }
    }

    public appendD(name, value1, value2) {
      if (this.body === '') {
        this.body = encodeURIComponent(name) + '='
          + encodeURIComponent(value1) + '|' + encodeURIComponent(value2);
      } else {
        this.body = this.body + '&' + encodeURIComponent(name) + '='
          + encodeURIComponent(value1) + '|' + encodeURIComponent(value2);
      }
    }

    public toString() {
      return this.body;
    }

  }
