import {Injectable} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {LoadingComponent} from './loading.component';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    loading;

    constructor(
        private popoverController: PopoverController
    ) {
    }

    async present() {
        this.loading = await this.popoverController.create({
            component: LoadingComponent,
            backdropDismiss: false,
            cssClass: 'loading-modal'
        });
        return this.loading.present();
    }

    async dismiss() {
        await this.loading.dismiss();
    }
}
