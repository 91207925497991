import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./services/auth-guard.service";

// access-control
// store-login
const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "store-login",
    loadChildren: () =>
      import("./store-login/store-login.module").then(
        (m) => m.StoreLoginPageModule
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard-v1/dashboard-v1.module").then(
        (m) => m.DashboardV1PageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "dashboard-timeline",
    loadChildren: () =>
      import("./dashboard-timeline/dashboard-timeline.module").then(
        (m) => m.DashboardTimelinePageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "dashboard-documents",
    loadChildren: () =>
      import("./dashboard-documents/dashboard-documents.module").then(
        (m) => m.DashboardDocumentsPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "dashboard-machines",
    loadChildren: () =>
      import("./dashboard-machines/dashboard-machines.module").then(
        (m) => m.DashboardMachinesPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "dashboard-stores",
    loadChildren: () =>
      import("./dashboard-stores/dashboard-stores.module").then(
        (m) => m.DashboardStoresPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "dashboard-machines-v1",
    loadChildren: () =>
      import("./dashboard-machines-v1/dashboard-machines-v1.module").then(
        (m) => m.DashboardMachinesV1PageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "store-form-status",
    loadChildren: () =>
      import("./store-form-status/store-form-status.module").then(
        (m) => m.StoreFormStatusPageModule
      ),
  },
  {
    path: "maintenance/search",
    loadChildren: () =>
      import("./maintenance/search/search.module").then(
        (m) => m.SearchPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "maintenance/dashboard",
    loadChildren: () =>
      import("./maintenance/dashboard/dashboard.module").then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "suppliers/dashboard",
    loadChildren: () =>
      import("./suppliers/dashboard/dashboard.module").then(
        (m) => m.DashboardPageModule
      ),
  },
  {
    path: "suppliers/list",
    loadChildren: () =>
      import("./suppliers/list/list.module").then((m) => m.ListPageModule),
  },
  {
    path: "tasks",
    loadChildren: () =>
      import("./task-detail/task-detail.module").then(
        (m) => m.TaskDetailPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "products",
    loadChildren: () =>
      import("./products/products.module").then((m) => m.ProductsModule),
      canActivate: [AuthGuardService],
  },
  {
    path: "media",
    loadChildren: () => import("./media/media.module").then((m) => m.MediaModule),
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
