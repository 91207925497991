import { Component, OnInit, Input } from '@angular/core';
import { Task } from 'src/model/task/task';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/api/api.service';
import { Media } from 'src/model/media/media';
import { BO_URL } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss'],
})
export class TaskDetailComponent implements OnInit {

  protected basePath = BO_URL;
  taskTypeObject;
  lastResponse;

  @Input() task: Task;
  @Input() companyId:string;

  public detailUrl: string;
  public arrayEntries: any;
  public medias: any[] = [];
  public tab = 'info';
  public styleH: string = 'auto';
  public viewerConfig: any = {
    btnClass: 'default', // The CSS class(es) that will apply to the buttons
    zoomFactor: 0.1, // The amount that the scale will be increased by
    containerBackgroundColor: '#ccc', // The color to use for the background. This can provided in hex, or rgb(a).
    wheelZoom: true, // If true, the mouse wheel can be used to zoom in
    allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to enter fullscreen mode
    allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
    btnIcons: {
      // The icon classes that will apply to the buttons. By default, font-awesome is used.
      zoomIn: 'fa fa-plus',
      zoomOut: 'fa fa-minus',
      rotateClockwise: 'fa fa-redo',
      rotateCounterClockwise: 'fa fa-undo',
      next: 'fa fa-arrow-right',
      prev: 'fa fa-arrow-left',
      fullscreen: 'fa fa-arrows-alt',
    },
    btnShow: {
      zoomIn: true,
      zoomOut: true,
      rotateClockwise: true,
      rotateCounterClockwise: true,
      next: true,
      prev: true,
    },
  };

  constructor(
    private route: ActivatedRoute,
    public modalController: ModalController,
    private apiService: ApiService
  ) { }

  async ngOnInit() {

    if (this.task) {
      this.detailUrl = `${this.basePath}/companies/${this.companyId}/stores/${this.task.target.storeId}/taskhistory/${this.task.id}/${this.task.type}`;
      this.lastResponse = this.task.responses?this.task.responses[0]:{};
    }

    const type = await this.apiService.getCustomTaskType(this.companyId, this.task.target.storeId, this.task.type).toPromise();
    if (type && type.length > 0) {
      this.taskTypeObject = type[0];
    }
    //response tipe fotos
    // taskTypeObject?.metadata?.responseFields.filter(r => r.type === 'photo').map(f => {

    // });

    //this.medias.push([await this.getMedia('ckc0sdirn00024d7ph73zn5ct')]);
    //this.medias.push([await this.getMedia('ckc0sdqps00034d7p6op7s7uh')]);
  }

  async showPictureInNewTab(id){
    const media = await this.getMedia(id);
    if(media) {
      window.open(URL.createObjectURL(media as Blob));
    }
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  show(tab) {
    this.tab = tab;
  }

  segmentChanged(ev: any) {
    this.tab = ev.detail.value;
  }

  async getMedia(mediaId: string) {
    if(!mediaId) return;
    
    const media = await this.apiService.getMediaById(mediaId).toPromise();

    if (media == null || media.size === 0) {
      return '';
    }
    return media;
  }

  arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  processCondition(field, response?) {
    response = response || this.lastResponse;
    if (field.condition === undefined || field.condition === null || field.condition === '') return true;
    const conditions = field.condition.split(', ');
    if (conditions.length === 0) return true;
    return conditions.filter(c => {
      if (c.includes('===')) {
        const sides = c.split('===');
        const leftSide = sides[0];
        const rightSide = sides[1];
        return response[leftSide] === rightSide;
      } else if (c === 'expirationDate') {
        if (response['Data de validade'] && moment(response['Data de validade']).isBefore(moment().add(4, 'days'))) {
          return true;
        }
        return false;
      }
      if (c[0] === '!') {
        const condition = c.substring(1, c.length);
        return !response[condition];
      }
      return !!response[c];
    }).length === conditions.length;
  }

  stringEquals(arrayItem, object) {
    return arrayItem === object;
  }

  toggleHideGroupFields(responseField, index, responseFields) {
    if (!responseField.isGrouper) return;
    this.toggleHideGroupsField2(responseField, index, responseFields);
  }

  toggleHideGroupsField2(responseField, index, responseFields) {
    for (let i = index; i < responseFields.length; ++i) {
      if (responseFields[i] !== responseField && this.taskTypeObject.metadata.responseFields[i].isGrouper) return;
      responseFields[i].isHiding = !responseFields[i].isHiding;
    }
  }

  toggleHideGroupsField3(responseField, index, responseFields) {
    for (let i = index + 1; i < responseFields.length; ++i) {
      if (responseFields[i].isGrouper) return;
      this.taskTypeObject.metadata.responseFields[i].isHiding = !this.taskTypeObject.metadata.responseFields[i].isHiding;
    }
  }

  toggleOffHideGroupFields(responseField, responseFields) {
    let startingIndex, isValid;
    for (let i = 0; i < responseFields.length; ++i) {
      if (responseFields[i].isGrouper) {
        startingIndex = i;
      }
      if (responseFields[i] === responseField) {
        isValid = true;
        break;
      }
    }
    if (!isValid) return;
    this.toggleHideGroupsField3(responseField, startingIndex, responseFields);
  }
}
