import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingComponent} from './loading.component';
import {LoadingService} from './loading.service';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [
        LoadingComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ],
    providers: [
        LoadingService
    ],
    entryComponents: [
        LoadingComponent
    ]
})
export class LoadingModule {
}
