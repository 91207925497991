import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;

  constructor(
      private popoverController: PopoverController
  ) { }

  ngOnInit() {}

  async close() {
    await this.popoverController.dismiss();
  }

}
