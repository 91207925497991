import { Component } from '@angular/core';

import { MenuController, Platform, PopoverController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from './state/state.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    public appPages = [
        {
            title: 'TASKS',
            url: '/dashboard',
            icon: 'clipboard-outline'
        },
        {
            title: 'Reports',
            url: '/dashboard-documents',
            icon: 'document-text-outline'
        },
        {
            title: 'Temperature sensors',
            url: '/dashboard-machines-v1',
            icon: 'thermometer'
        },
        {
            title: 'Maintenance',
            url: '/maintenance/dashboard',
            icon: 'build'
        },
        {
            title: 'Suppliers',
            url: '/suppliers/dashboard',
            icon: 'train'
        },
        {
            title: 'Products',
            url: '/products',
            icon: 'basket'
        }
    ];

    runningVersion = 'v2.0.0';
    runningMachine = '';


    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public translate: TranslateService,
        private popoverController: PopoverController,
        private router: Router,
        private stateService: StateService,
        private menu: MenuController
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            this.translate.addLangs(['en', 'pt', 'es']);
            this.translate.setDefaultLang('pt');
            const browserLang = this.translate.getBrowserLang();
            this.translate.use(browserLang.match(/en|pt|es/) ? browserLang : 'pt');
        });
    }

    async switchToManuals() {
        // Navigate to Manuals Page
        //        this.app.getRootNav().push(ManualsPage, {}, {animate: false});
        await this.popoverController.dismiss();
    }

    async pressLogout() {
        // Clear local storage
        await this.stateService.clearLocalStorage();
        await this.stateService.removeTokenResponse();
        await this.menu.close('first');
        // Navigate to StoreLoginPage
        await this.router.navigate(['/store-login']);
    }
}
