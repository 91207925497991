import {Injectable} from "@angular/core";
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse,} from "@angular/common/http";
import {from, Observable, of} from "rxjs";
import {StoreAuthenticationInfo} from "../model/store-authentication-info";
import {TokenResponse} from "../model/token-response";
import {FieldsTree} from "../model/field/field";
import {StateService} from "../app/state/state.service";
import {flatMap} from "rxjs/operators";
import {Employee} from "../model/employee/employee";
import {Task} from "src/model/task/task";
import {API_URL} from "../environments/environment";
import {MachineOverview} from 'src/model/MachineOverview';
import {UrlEncodedString} from "../app/utils/UrlEncodedString";

@Injectable({
    providedIn: "root",
})
export class ApiService {

    protected basePath = API_URL;

    public defaultHeaders = new HttpHeaders();

    constructor(
        private httpClient: HttpClient,
        private stateService: StateService
    ) {
    }

    public authenticateStore(
        companyName: string,
        storeCode: string,
        password: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<StoreAuthenticationInfo>;
    public authenticateStore(
        companyName: string,
        storeCode: string,
        password: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<StoreAuthenticationInfo>>;
    public authenticateStore(
        companyName: string,
        storeCode: string,
        password: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<StoreAuthenticationInfo>>;
    public authenticateStore(
        companyName: string,
        storeCode: string,
        password: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        let headers = this.defaultHeaders;
        headers = headers.set("Content-type", "application/json");

        return this.httpClient.post<StoreAuthenticationInfo>(
            `${this.basePath}/storeAuth`,
            {
                companyName,
                storeCode,
                password,
            },
            {
                headers,
                observe,
                reportProgress,
            }
        );
    }

    public authenticate(
        username: string,
        password: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<TokenResponse>;
    public authenticate(
        username: string,
        password: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<TokenResponse>>;
    public authenticate(
        username: string,
        password: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<TokenResponse>>;
    public authenticate(
        username: string,
        password: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        let headers = this.defaultHeaders;
        headers = headers.set("Content-type", "application/json");

        return this.httpClient.post<StoreAuthenticationInfo>(
            `${this.basePath}/authentication`,
            {
                username,
                password,
            },
            {
                headers,
                observe,
                reportProgress,
            }
        );
    }

    public getDashboardTasksFilters(
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<any>>;
    public getDashboardTasksFilters(
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getDashboardTasksFilters(
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<any>>>;
    public getDashboardTasksFilters(
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get<any>(
                    `${this.basePath}/dashboard-tasks/filters`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getMaintenaceFilters(
        types?: string[],
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<any>>;
    public getMaintenaceFilters(
        types?: string[],
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getMaintenaceFilters(
        types?: string[],
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<any>>>;
    public getMaintenaceFilters(
        types?: string[],
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                var q = "";

                if (types) {
                    q = `types=${types.join()}`;
                }

                return this.httpClient.get<any>(
                    `${this.basePath}/maintenance/filters?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getSuppliersFilters(
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<any>>;
    public getSuppliersFilters(
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getSuppliersFilters(
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<any>>>;
    public getSuppliersFilters(
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                var q = "";


                return this.httpClient.get<any>(
                    `${this.basePath}/suppliers/filters?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getEmployeesByCompanyAndStoreId(
        companyId: string,
        storeId: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<Employee>>>;
    public getEmployeesByCompanyAndStoreId(
        companyId: string,
        storeId: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<Employee>>>;
    public getEmployeesByCompanyAndStoreId(
        companyId: string,
        storeId: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get<Array<Employee>>(
                    `${this.basePath}/companies/${companyId}/stores/${storeId}/employees`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getFieldsTreeByCompanyAndStoreId(
        startDate: number,
        endDate: number,
        companyId: string,
        storeIds: string[],
        userIds: string[],
        areaIds: string[],
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<FieldsTree>>;
    public getFieldsTreeByCompanyAndStoreId(
        startDate: number,
        endDate: number,
        companyId: string,
        storeIds: string[],
        userIds: string[],
        areaIds: string[],
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<FieldsTree>>>;
    public getFieldsTreeByCompanyAndStoreId(
        startDate: number,
        endDate: number,
        companyId: string,
        storeIds: string[],
        userIds: string[],
        areaIds: string[],
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<FieldsTree>>>;
    public getFieldsTreeByCompanyAndStoreId(
        startDate: number,
        endDate: number,
        companyId: string,
        storeIds: string[],
        userIds: string[],
        areaIds: string[],
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `startDate=${startDate}&endDate=${endDate}&companyIds=${companyId}`;

                if (storeIds && storeIds.length > 0) {
                    q += `&storeIds=${storeIds}`;
                }

                if (userIds && userIds.length > 0) {
                    q += `&userIds=${userIds.join(",")}`;
                }

                if (areaIds && areaIds.length > 0) {
                    q += `&areaIds=${areaIds.join(",")}`;
                }

                return this.httpClient.get<Array<FieldsTree>>(
                    `${this.basePath}/dashboard-tasks?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );


    }

    public getDashboardOverview(
        startDate: number,
        endDate: number,
        companyId: string,
        storeIds: string[],
        userIds: string[],
        areaIds: string[],
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<FieldsTree>>;
    public getDashboardOverview(
        startDate: number,
        endDate: number,
        companyId: string,
        storeIds: string[],
        userIds: string[],
        areaIds: string[],
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<FieldsTree>>>;
    public getDashboardOverview(
        startDate: number,
        endDate: number,
        companyId: string,
        storeIds: string[],
        userIds: string[],
        areaIds: string[],
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<FieldsTree>>>;
    public getDashboardOverview(
        startDate: number,
        endDate: number,
        companyId: string,
        storeIds: string[],
        userIds: string[],
        areaIds: string[],
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `startDate=${startDate}&endDate=${endDate}&companyIds=${companyId}`;

                if (storeIds && storeIds.length > 0) {
                    q += `&storeIds=${storeIds}`;
                }

                if (userIds && userIds.length > 0) {
                    q += `&userIds=${userIds.join(",")}`;
                }

                if (areaIds && areaIds.length > 0) {
                    q += `&areaIds=${areaIds.join(",")}`;
                }

                return this.httpClient.get<Array<FieldsTree>>(
                    `${this.basePath}/dashboard-overview?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getDashboardNodeData(id: string, filter: any, type: string,
                                observe?: "body",
                                reportProgress?: boolean
    ): Observable<Array<any>>;
    public getDashboardNodeData(id: string, filter: any, type: string,
                                observe?: "response",
                                reportProgress?: boolean
    ): Observable<HttpResponse<Array<any>>>;
    public getDashboardNodeData(id: string, filter: any, type: string,
                                observe?: "events",
                                reportProgress?: boolean
    ): Observable<HttpEvent<Array<any>>>;
    public getDashboardNodeData(id: string, filter: any, type: string,
                                observe: any = "body",
                                reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `type=${type}&startDate=${filter.startDate}&endDate=${filter.endDate}&companyIds=${filter.selectedCompany}`;

                if (filter.selectedStores && filter.selectedStores.length > 0) {
                    q += `&storeIds=${filter.selectedStores.join(",")}`;
                }

                if (filter.usersIds && filter.usersIds.length > 0) {
                    q += `&userIds=${filter.usersIds.join(",")}`;
                }

                if (filter.areasIds && filter.areasIds.length > 0) {
                    q += `&areaIds=${filter.areasIds.join(",")}`;
                }

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/dashboard-overview-node/${id}?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }


    dashboardStoreDataEtag = "";

    public getDashboardStoreData(id: string, filter: any,
                                 observe?: "body",
                                 reportProgress?: boolean
    ): Observable<Array<FieldsTree>>;
    public getDashboardStoreData(id: string, filter: any,
                                 observe?: "response",
                                 reportProgress?: boolean
    ): Observable<HttpResponse<Array<FieldsTree>>>;
    public getDashboardStoreData(id: string, filter: any,
                                 observe?: "events",
                                 reportProgress?: boolean
    ): Observable<HttpEvent<Array<FieldsTree>>>;
    public getDashboardStoreData(id: string, filter: any,
                                 observe: any = "response",
                                 reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);
                if (this.dashboardStoreDataEtag) {
                    headers = headers.set("If-None-Match", this.dashboardStoreDataEtag);
                }

                let q = `startDate=${filter.startDate}&endDate=${filter.endDate}&companyIds=${filter.selectedCompany}`;

                if (filter.selectedStores && filter.selectedStores.length > 0) {
                    q += `&storeIds=${filter.selectedStores.join(",")}`;
                }

                if (filter.usersIds && filter.usersIds.length > 0) {
                    q += `&userIds=${filter.usersIds.join(",")}`;
                }

                if (filter.areasIds && filter.areasIds.length > 0) {
                    q += `&areaIds=${filter.areasIds.join(",")}`;
                }

                return this.httpClient.get<HttpResponse<Array<FieldsTree>>>(
                    `${this.basePath}/dashboard-stores/${id}/overview?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    // public getStore(
    //   storeId: string,
    //   observe?: "body",
    //   reportProgress?: boolean
    // ): Observable<Array<Store>>;
    // public getStore(
    //   storeId: string,
    //   observe?: "response",
    //   reportProgress?: boolean
    // ): Observable<HttpResponse<Array<Store>>>;
    // public getStore(
    //   storeId: string,
    //   observe?: "events",
    //   reportProgress?: boolean
    // ): Observable<HttpEvent<Array<Store>>>;
    // public getStore(
    //   storeId: string,
    //   observe: any = "body",
    //   reportProgress: boolean = false
    // ): Observable<any> {
    //   return from(this.stateService.getTokenResponse()).pipe(
    //     flatMap((tokenResponse) => {

    //       let headers = this.defaultHeaders;
    //       headers = headers.set("Content-type", "application/json");
    //       headers = headers.set("access-token", tokenResponse.token);

    //       //let q = `startDate=${startDate}&endDate=${endDate}&companyIds=${companyId}`;

    //       return this.httpClient.get<Array<Store>>(
    //         `${this.basePath}/dashboard-stores/${storeId}/overview?${q}`,
    //         {
    //           headers,
    //           observe,
    //           reportProgress,
    //         }
    //       );
    //     })
    //   );
    // }

    public getDashboardForStore(
        companyId: string,
        storeId: string,
        startDate: number,
        endDate: number,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<any>>;
    public getDashboardForStore(companyId: string,
                                storeId: string,
                                startDate: number,
                                endDate: number,
                                observe?: "response",
                                reportProgress?: boolean
    ): Observable<HttpResponse<Array<any>>>;
    public getDashboardForStore(companyId: string,
                                storeId: string,
                                startDate: number,
                                endDate: number,
                                observe?: "events",
                                reportProgress?: boolean
    ): Observable<HttpEvent<Array<any>>>;
    public getDashboardForStore(companyId: string,
                                storeId: string,
                                startDate: number,
                                endDate: number,
                                observe: any = "body",
                                reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `startDate=${startDate}&endDate=${endDate}&companyIds=${companyId}`;
                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/dashboard-stores/${storeId}/overview?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getDashboardForStores(
        companyId: string,
        storeIds: string[],
        startDate: number,
        endDate: number,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<any>>;
    public getDashboardForStores(
        companyId: string,
        storeIds: string[],
        startDate: number,
        endDate: number,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<any>>>;
    public getDashboardForStores(
        companyId: string,
        storeIds: string[],
        startDate: number,
        endDate: number,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<any>>>;
    public getDashboardForStores(
        companyId: string,
        storeIds: string[],
        startDate: number,
        endDate: number,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `startDate=${startDate}&endDate=${endDate}&companyIds=${companyId}&storeIds=${storeIds.join(',')}`;


                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/dashboard-stores/overview?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getTimelineTasks(
        formId: string,
        startDate: number,
        endDate: number,
        storeId?: string,
        userId?: string,
        title?: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<Task>>;
    public getTimelineTasks(
        formId: string,
        startDate: number,
        endDate: number,
        storeId?: string,
        userId?: string,
        title?: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<Task>>>;
    public getTimelineTasks(
        formId: string,
        startDate: number,
        endDate: number,
        storeId?: string,
        userId?: string,
        title?: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<Task>>>;
    public getTimelineTasks(
        formId: string,
        startDate: number,
        endDate: number,
        storeId?: string,
        userId?: string,
        title?: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);
                let q = `startDate=${startDate}&endDate=${endDate}`;

                if (storeId) {
                    q += `&storeId=${storeId}`;
                }

                if (userId) {
                    q += `&userId=${userId}`;
                }

                if (title) {
                    q += `&title=${encodeURIComponent(title)}`;
                }

                return this.httpClient.get<Array<FieldsTree>>(
                    `${this.basePath}/dashboard-timeline/${formId}?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getTimelineTasksForStore(
        storeId: string,
        startDate: number,
        endDate: number,
        formId?: string,
        type?: string,
        subType?: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<any>>;
    public getTimelineTasksForStore(
        storeId: string,
        startDate: number,
        endDate: number,
        formId?: string,
        type?: string,
        subType?: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<any>>>;
    public getTimelineTasksForStore(
        storeId: string,
        startDate: number,
        endDate: number,
        formId?: string,
        type?: string,
        subType?: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<any>>>;
    public getTimelineTasksForStore(
        storeId: string,
        startDate: number,
        endDate: number,
        formId?: string,
        type?: string,
        subType?: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);
                let q = `startDate=${startDate}&endDate=${endDate}`;

                if (type) {
                    q += `&type=${type}`
                }

                if (subType) {
                    q += `&subType=${subType}`
                }

                if (formId) {
                    q += `&formId=${formId}`
                }

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/dashboard-store-tasks-timeline/${storeId}?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getMediaById(
        mediaId: string,
    ): Observable<Blob> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "image/jpeg");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get(
                    `${this.basePath}/media/${mediaId}`,
                    {
                        headers,
                        responseType: 'blob'
                    },
                );
            })
        );
    }


    public getTimelineMachines(
        companyId: string,
        storeId: string,
        machineIds?: string[],
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<any>>;
    public getTimelineMachines(
        companyId: string,
        storeId: string,
        machineIds?: string[],
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<any>>>;
    public getTimelineMachines(
        companyId: string,
        storeId: string,
        machineIds?: string[],
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<any>>>;
    public getTimelineMachines(
        companyId: string,
        storeId: string,
        machineIds?: string[],
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = ``;

                if (machineIds && machineIds.length > 0) {
                    q += `?machineIds=${machineIds.join(",")}`;
                }

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/dashboard-machines/companies/${companyId}/stores/${storeId}/machines${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }


    public getTimelineMachinesData(
        storeId: string,
        startDate: number,
        endDate: number,
        machineIds?: string[],
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<any>>;
    public getTimelineMachinesData(
        storeId: string,
        startDate: number,
        endDate: number,
        machineIds?: string[],
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<any>>>;
    public getTimelineMachinesData(
        storeId: string,
        startDate: number,
        endDate: number,
        machineIds?: string[],
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<any>>>;
    public getTimelineMachinesData(
        storeId: string,
        startDate: number,
        endDate: number,
        machineIds?: string[],
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `startDate=${startDate}&endDate=${endDate}`;

                if (machineIds && machineIds.length > 0) {
                    q += `&machineIds=${machineIds.join(",")}`;
                }

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/dashboard-machines/${storeId}?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getStoresMachinesOverview(
        stores: string[],
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<MachineOverview>>;
    public getStoresMachinesOverview(
        stores: string[],
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<MachineOverview>>>;
    public getStoresMachinesOverview(
        stores: string[],
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<MachineOverview>>>;
    public getStoresMachinesOverview(
        stores: string[],
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = ``;

                if (stores && stores.length > 0) {
                    q += `stores=${stores.join(",")}`;
                }

                return this.httpClient.get<Array<MachineOverview>>(
                    `${this.basePath}/dashboard-machines-overview?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getDashboardMachinesFilters(
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getDashboardMachinesFilters(
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getDashboardMachinesFilters(
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getDashboardMachinesFilters(
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get<any>(
                    `${this.basePath}/dashboard/machines/filters`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getDashboardDocumentsFilters(
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getDashboardDocumentsFilters(
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getDashboardDocumentsFilters(
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getDashboardDocumentsFilters(
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get<any>(
                    `${this.basePath}/dashboard/documents/filters`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getMachineDocument(
        documentId: string,
        startDate: number,
        endDate: number,
        machineId: string,
        storeId: string,
        companyId: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap<TokenResponse, Observable<HttpResponse<any>>>((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get(
                    `${this.basePath}/companies/${companyId}/stores/${storeId}/documents/${documentId}?startDate=${startDate}&endDate=${endDate}&machineId=${machineId}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                        responseType: "blob"
                    }
                )
            })
        )
    }

    public getStoresTasksStatus(
        companyIds: string,
        storeIds: string,
        startDate: number,
        endDate: number,
        formId?: string,
        type?: string,
        subType?: string,
        groupBy?: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<any>>;
    public getStoresTasksStatus(
        companyIds: string,
        storeIds: string,
        startDate: number,
        endDate: number,
        formId?: string,
        type?: string,
        subType?: string,
        groupBy?: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<any>>>;
    public getStoresTasksStatus(
        companyIds: string,
        storeIds: string,
        startDate: number,
        endDate: number,
        formId?: string,
        type?: string,
        subType?: string,
        groupBy?: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<any>>>;
    public getStoresTasksStatus(
        companyIds: string,
        storeIds: string,
        startDate: number,
        endDate: number,
        formId?: string,
        type?: string,
        subType?: string,
        groupBy?: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `companyIds=${companyIds}&storeIds=${storeIds}&startDate=${startDate}&endDate=${endDate}`;

                if (type) {
                    q += `&type=${type}`
                }

                if (subType) {
                    q += `&subType=${subType}`
                }

                if (formId) {
                    q += `&formId=${formId}`
                }

                if (groupBy) {
                    q += `&groupBy=${groupBy}`
                }

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/dashboard-stores-status?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getMachine(
        companyId: string,
        storeId: string,
        machineId: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getMachine(
        companyId: string,
        storeId: string,
        machineId: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getMachine(
        companyId: string,
        storeId: string,
        machineId: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getMachine(
        companyId: string,
        storeId: string,
        machineId: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get<any>(
                    `${this.basePath}/companies/${companyId}/stores/${storeId}/machines/${machineId}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getMachines(
        query: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<Array<any>>;
    public getMachines(
        query: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<any>>>;
    public getMachines(
        query: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<any>>>;
    public getMachines(
        query: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = ``;

                if (query) {
                    q += `&q=${query}`
                }


                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/maintenance/machines?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getMachinesMaintenance(
        stores: string,
        startDate: number,
        endDate: number,
        machines?: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getMachinesMaintenance(
        stores: string,
        startDate: number,
        endDate: number,
        machines?: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getMachinesMaintenance(
        stores: string,
        startDate: number,
        endDate: number,
        machines?: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getMachinesMaintenance(
        stores: string,
        startDate: number,
        endDate: number,
        machines?: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `stores=${stores}&startDate=${startDate}&endDate=${endDate}`;

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/maintenance/machines/tasks?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getMachineMaintenance(
        machineId: string,
        startDate: number,
        endDate: number,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getMachineMaintenance(
        machineId: string,
        startDate: number,
        endDate: number,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getMachineMaintenance(
        machineId: string,
        startDate: number,
        endDate: number,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getMachineMaintenance(
        machineId: string,
        startDate: number,
        endDate: number,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `startDate=${startDate}&endDate=${endDate}`;

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}//maintenance/machines/${machineId}/tasks?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getMaintenance(
        companyId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        machines?: string[],
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getMaintenance(
        companyId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        machines?: string[],
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getMaintenance(
        companyId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        machines?: string[],
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getMaintenance(
        companyId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        machines?: string[],
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `startDate=${startDate}&endDate=${endDate}`;

                if (stores) {
                    q += `&stores=${stores.join()}`;
                }
                if (machines) {
                    q += `&machines=${machines.join()}`;
                }

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/maintenance/companies/${companyId}/machines/tasks?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getCustomTaskType(
        companyId: string,
        storeId: string,
        type: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getCustomTaskType(
        companyId: string,
        storeId: string,
        type: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getCustomTaskType(
        companyId: string,
        storeId: string,
        type: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getCustomTaskType(
        companyId: string,
        storeId: string,
        type: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `type=${type || "maintenance"}`;

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/companies/${companyId}/stores/${storeId}/custom-task-types?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getSuppliersDashboard(
        companyId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        suppliers?: string[],
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getSuppliersDashboard(
        companyId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        suppliers?: string[],
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getSuppliersDashboard(
        companyId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        suppliers?: string[],
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getSuppliersDashboard(
        companyId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        suppliers?: string[],
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `startDate=${startDate}&endDate=${endDate}`;

                if (stores && stores.length > 0) {
                    q += `&stores=${stores.join()}`;
                }

                if (suppliers && suppliers.length > 0) {
                    q += `&suppliers=${suppliers.join()}`;
                }

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/companies/${companyId}/suppliers/dashboard?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getSuppliersTasksStatus(
        companyId: string,
        supplierId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getSuppliersTasksStatus(
        companyId: string,
        supplierId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getSuppliersTasksStatus(
        companyId: string,
        supplierId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getSuppliersTasksStatus(
        companyId: string,
        supplierId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `startDate=${startDate}&endDate=${endDate}`;

                if (stores && stores.length > 0) {
                    q += `&stores=${stores.join()}`;
                }

                // return of([
                //   {
                //     total: 10,
                //     conformTotal: 1,
                //     productDamagedTotal: 9,
                //     date: "2021-01-15"
                //   },
                //   {
                //     total: 12,
                //     conformTotal: 5,
                //     overdueTotal: 5,
                //     productDamagedTotal: 1,
                //     productNonConformTotal: 1,
                //     date: "2021-01-16"
                //   }
                // ]
                // )

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/companies/${companyId}/suppliers/${supplierId}/dashboard?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getTimelineTasksForSupplier(
        companyId: string,
        supplierId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getTimelineTasksForSupplier(
        companyId: string,
        supplierId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getTimelineTasksForSupplier(
        companyId: string,
        supplierId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getTimelineTasksForSupplier(
        companyId: string,
        supplierId: string,
        startDate: number,
        endDate: number,
        stores?: string[],
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                let q = `startDate=${startDate}&endDate=${endDate}`;

                if (stores && stores.length > 0) {
                    q += `&stores=${stores.join()}`;
                }

                return of([
                        {
                            "id": "ckjzbzma825wwswp49hw81d2v",
                            "formId": "cjcf3qg8h00dex1qm6z0958lt_automatic",
                            "title": "Medir Temperatura - Câmara Congelação Nº 2 (-18 a -21º C)  06180027",
                            "periodicity": "Única",
                            "visualizationDate": 1610821800080,
                            "startDate": 1610778600080,
                            "endDate": 1610793000080,
                            "type": "singleTemperature",
                            "generationType": "automatic",
                            "generationSubtype": "noSensorTemperatureReadings",
                            "machineId": "cjcrj4xmx000a3b6r7j1z4sn9",
                            "unit": "ºC",
                            "responses": [
                                {
                                    "isInConformity": false,
                                    "date": 1610778600080,
                                    "clientDate": 1610778600080,
                                    "numberController": "",
                                    "employeeId": "",
                                    "non_conformity": "Leitura automática do sensor falhou",
                                    "picturesIds": []
                                }
                            ],
                            "target": {
                                "storeId": "cjcf3qg8h00dex1qm6z0958lt",
                                "userIds": []
                            },
                            "lastModified": 1610778600080,
                            "response": {
                                "isInConformity": false,
                                "date": 1610778600080,
                                "clientDate": 1610778600080,
                                "numberController": "",
                                "employeeId": "",
                                "non_conformity": "Leitura automática do sensor falhou",
                                "picturesIds": []
                            },
                            "conform": 0,
                            "nonConform": 1,
                            "nonConformJustified": 0,
                            "nonConformGeral": 0,
                            "noValidSensorTemperatureReadings": 0,
                            "noSensorTemperatureReadings": 1,
                            "overdue": 0,
                            "expired": 0,
                            "unfilled": 0,
                            "noValidSensorTemperatureReadingsJustified": 0,
                            "noSensorTemperatureReadingsJustified": 0,
                            "nonConformGeralJustified": 0,
                            "formName": "Não Conformidades"
                        },
                        {
                            "id": "ckjzbzma925wxswp4pswq90fk",
                            "formId": "cjcf3qg8h00dex1qm6z0958lt_automatic",
                            "title": "Medir Temperatura - Câmara Refrigeração Nº 1 (0 a 5ºC) 06180026",
                            "periodicity": "Única",
                            "visualizationDate": 1610821800081,
                            "startDate": 1610778600081,
                            "endDate": 1610793000081,
                            "type": "singleTemperature",
                            "generationType": "automatic",
                            "generationSubtype": "noSensorTemperatureReadings",
                            "machineId": "cjcrj2wad00093b6rmskddvk2",
                            "unit": "ºC",
                            "responses": [
                                {
                                    "isInConformity": false,
                                    "date": 1610778600081,
                                    "clientDate": 1610778600081,
                                    "numberController": "",
                                    "employeeId": "",
                                    "non_conformity": "Leitura automática do sensor falhou",
                                    "picturesIds": []
                                }
                            ],
                            "target": {
                                "storeId": "cjcf3qg8h00dex1qm6z0958lt",
                                "userIds": []
                            },
                            "lastModified": 1610778600081,
                            "response": {
                                "isInConformity": false,
                                "date": 1610778600081,
                                "clientDate": 1610778600081,
                                "numberController": "",
                                "employeeId": "",
                                "non_conformity": "Leitura automática do sensor falhou",
                                "picturesIds": []
                            },
                            "conform": 0,
                            "nonConform": 1,
                            "nonConformJustified": 0,
                            "nonConformGeral": 0,
                            "noValidSensorTemperatureReadings": 0,
                            "noSensorTemperatureReadings": 1,
                            "overdue": 0,
                            "expired": 0,
                            "unfilled": 0,
                            "noValidSensorTemperatureReadingsJustified": 0,
                            "noSensorTemperatureReadingsJustified": 0,
                            "nonConformGeralJustified": 0,
                            "formName": "Não Conformidades"
                        },
                        {
                            "id": "ckjzva2ad26r4swp4xyh890zx",
                            "formId": "cjcf3qg8h00dex1qm6z0958lt_automatic",
                            "title": "Medir Temperatura - Câmara Refrigeração Nº 1 (0 a 5ºC) 06180026",
                            "periodicity": "Única",
                            "visualizationDate": 1610854200084,
                            "startDate": 1610811000084,
                            "endDate": 1610825400084,
                            "type": "singleTemperature",
                            "generationType": "automatic",
                            "generationSubtype": "noSensorTemperatureReadings",
                            "machineId": "cjcrj2wad00093b6rmskddvk2",
                            "unit": "ºC",
                            "responses": [
                                {
                                    "isInConformity": false,
                                    "date": 1610811000084,
                                    "clientDate": 1610811000084,
                                    "numberController": "",
                                    "employeeId": "",
                                    "non_conformity": "Leitura automática do sensor falhou",
                                    "picturesIds": []
                                }
                            ],
                            "target": {
                                "storeId": "cjcf3qg8h00dex1qm6z0958lt",
                                "userIds": []
                            },
                            "lastModified": 1610811000085,
                            "response": {
                                "isInConformity": false,
                                "date": 1610811000084,
                                "clientDate": 1610811000084,
                                "numberController": "",
                                "employeeId": "",
                                "non_conformity": "Leitura automática do sensor falhou",
                                "picturesIds": []
                            },
                            "conform": 0,
                            "nonConform": 1,
                            "nonConformJustified": 0,
                            "nonConformGeral": 0,
                            "noValidSensorTemperatureReadings": 0,
                            "noSensorTemperatureReadings": 1,
                            "overdue": 0,
                            "expired": 0,
                            "unfilled": 0,
                            "noValidSensorTemperatureReadingsJustified": 0,
                            "noSensorTemperatureReadingsJustified": 0,
                            "nonConformGeralJustified": 0,
                            "formName": "Não Conformidades"
                        },
                        {
                            "id": "ckjzva2af26r6swp4fi588lek",
                            "formId": "cjcf3qg8h00dex1qm6z0958lt_automatic",
                            "title": "Medir Temperatura - Câmara Congelação Nº 2 (-18 a -21º C)  06180027",
                            "periodicity": "Única",
                            "visualizationDate": 1610854200087,
                            "startDate": 1610811000087,
                            "endDate": 1610825400087,
                            "type": "singleTemperature",
                            "generationType": "automatic",
                            "generationSubtype": "noSensorTemperatureReadings",
                            "machineId": "cjcrj4xmx000a3b6r7j1z4sn9",
                            "unit": "ºC",
                            "responses": [
                                {
                                    "isInConformity": false,
                                    "date": 1610811000087,
                                    "clientDate": 1610811000087,
                                    "numberController": "",
                                    "employeeId": "",
                                    "non_conformity": "Leitura automática do sensor falhou",
                                    "picturesIds": []
                                }
                            ],
                            "target": {
                                "storeId": "cjcf3qg8h00dex1qm6z0958lt",
                                "userIds": []
                            },
                            "lastModified": 1610811000087,
                            "response": {
                                "isInConformity": false,
                                "date": 1610811000087,
                                "clientDate": 1610811000087,
                                "numberController": "",
                                "employeeId": "",
                                "non_conformity": "Leitura automática do sensor falhou",
                                "picturesIds": []
                            },
                            "conform": 0,
                            "nonConform": 1,
                            "nonConformJustified": 0,
                            "nonConformGeral": 0,
                            "noValidSensorTemperatureReadings": 0,
                            "noSensorTemperatureReadings": 1,
                            "overdue": 0,
                            "expired": 0,
                            "unfilled": 0,
                            "noValidSensorTemperatureReadingsJustified": 0,
                            "noSensorTemperatureReadingsJustified": 0,
                            "nonConformGeralJustified": 0,
                            "formName": "Não Conformidades"
                        }
                    ]
                )

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/companies/${companyId}/suppliers/${supplierId}/tasks?${q}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getDocumentTypes(
        companyId: string,
        storeId: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getDocumentTypes(
        companyId: string,
        storeId: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getDocumentTypes(
        companyId: string,
        storeId: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getDocumentTypes(
        companyId: string,
        storeId: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);


                return this.httpClient.get<Array<FieldsTree>>(
                    `${this.basePath}/companies/${companyId}/stores/${storeId}/documents`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }
    
    public getFODocumentTypes(
        companyId: string,
        storeId: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getFODocumentTypes(
        companyId: string,
        storeId: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getFODocumentTypes(
        companyId: string,
        storeId: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getFODocumentTypes(
        companyId: string,
        storeId: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);


                return this.httpClient.get<Array<FieldsTree>>(
                    `${this.basePath}/companies/${companyId}/stores/${storeId}/foDocuments`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getDocument(
        companyId: string,
        storeId: string,
        documentId: string,
        query: any,
        reportProgress?: boolean
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "blob");
                headers = headers.set("access-token", tokenResponse.token);

                let url = `${this.basePath}/companies/${companyId}/stores/${storeId}/documents/${documentId}?`;
                if (query) {
                    if (query.startDate && query.endDate) {
                        url = url.concat(buildStartAndEndDatesQuery(query.startDate, query.endDate));
                    }
                    if (query.formId) {
                        url = url.concat(`&formId=${query.formId}`);
                    }
                    if (query.isFilterByClientDate) {
                        url = url.concat(`&isFilterByClientDate=true`);
                    }
                }

                return this.httpClient.get<Observable<any>>(
                    url,
                    {
                        headers,
                        reportProgress,
                        observe: "response" as "body",
                        responseType: "blob" as "json"
                    }
                );
            })
        ) as Observable<any>;

        function buildStartAndEndDatesQuery(startDate, endDate) {
            const urlEncodedString = new UrlEncodedString();
            urlEncodedString.append("startDate", startDate);
            urlEncodedString.append("endDate", endDate);
            return urlEncodedString.toString();
        }
    }

    public getTaskDetail(
        id: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any>;
    public getTaskDetail(
        id: string,
        observe?: "response",
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public getTaskDetail(
        id: string,
        observe?: "events",
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public getTaskDetail(
        id: string,
        observe: any = "body",
        reportProgress: boolean = false
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/tasks/${id}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getTaskType(
        type: string,
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {

                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get<Array<any>>(
                    `${this.basePath}/custom-task-types/${type}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getDashboardProductsFilters(
        observe?: "body",
        reportProgress?: boolean
    ): Observable<any> {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get<any>(
                    `${this.basePath}/products/filters`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getStore(
        companyId: string,
        storeId: string,
        observe?: "body",
        reportProgress?: boolean
    ) {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get<any>(
                    `${this.basePath}/companies/${companyId}/stores/${storeId}?joinSuppliers&joinProducts`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getFieldsFromStore(
        companyId: string,
        storeId: string,
        observe?: "body",
        reportProgress?: boolean) {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get<any>(
                    `${this.basePath}/companies/${companyId}/stores/${storeId}/fields`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public updateProductFromStore(
        companyId: string,
        storeId: string,
        productId: string,
        product: any,
        observe?: "body",
        reportProgress?: boolean) {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.put<any>(
                    `${this.basePath}/companies/${companyId}/stores/${storeId}/products/${productId}`,
                    product,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }

    public getProductFromStore(
        companyId: string,
        storeId: string,
        productId: string,
        observe?: "body",
        reportProgress?: boolean) {
        return from(this.stateService.getTokenResponse()).pipe(
            flatMap((tokenResponse) => {
                let headers = this.defaultHeaders;
                headers = headers.set("Content-type", "application/json");
                headers = headers.set("access-token", tokenResponse.token);

                return this.httpClient.get<any>(
                    `${this.basePath}/companies/${companyId}/stores/${storeId}/products/${productId}`,
                    {
                        headers,
                        observe,
                        reportProgress,
                    }
                );
            })
        );
    }
}
