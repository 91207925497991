import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate  {

  constructor(private router: Router, private stateService: StateService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    var token =  await this.stateService.getTokenResponse();
    if (!token) {
      this.stateService.setReturnUrl(state.url).then(() => {
        this.router.navigate(['/store-login']);
    });
    return false;
    } else {
      console.log('Token encontrado, permitindo acesso...');
      // Outra lógica para quando o usuário está autenticado
    }
    
    return true;
  }

}
