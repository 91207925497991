import { Injectable } from "@angular/core";
import { StoreAuthenticationInfo } from "../../model/store-authentication-info";
import { Storage } from "@ionic/storage";
import { TokenResponse } from "../../model/token-response";
import { ApiService } from "../../api/api.service";
import { Employee } from "../../model/employee/employee";
import { Token } from "@angular/compiler";
import { getToken } from "codelyzer/angular/styles/cssLexer";
import { Operational } from "../../model/user/operational";

import { Company } from "src/model/company";
import { Store } from "src/model/store";

@Injectable({
  providedIn: "root",
})
export class StateService {
  constructor(private storage: Storage) {}

  // async setCompanies(value: Array<Company>): Promise<any> {
  //     return await this.storage.set('companies', value);
  // }

  // async getCompanies(): Promise<Array<Company>> {
  //     return await this.storage.get('companies');
  // }

  // async removeCompanies(): Promise<any> {
  //     return await this.storage.remove('companies');
  // }

  // async setStores(value: Array<Store>): Promise<any> {
  //     return await this.storage.set('stores', value);
  // }

  // async getStores(): Promise<Array<Store>> {
  //     return await this.storage.get('stores');
  // }

  // async removeStores(): Promise<any> {
  //     return await this.storage.remove('stores');
  // }

  async setCustomData(name: string, value: any): Promise<any> {
    return await this.storage.set(name, value);
  }

  async getCustomData(name: string): Promise<any> {
    return await this.storage.get(name);
  }

  async removeCustomData(name: string): Promise<any> {
    return await this.storage.remove(name);
  }

  async setTimelineData(value: any): Promise<any> {
    return await this.storage.set("timeline", value);
  }

  async getTimelineData(): Promise<any> {
    return await this.storage.get("timeline");
  }

  async removeTimelineData(): Promise<any> {
    return await this.storage.remove("timeline");
  }

  async setStoreAuthInfo(value: StoreAuthenticationInfo): Promise<any> {
    return await this.storage.set("storeAuthInfo", value);
  }

  async getStoreAuthInfo(): Promise<StoreAuthenticationInfo> {
    return await this.storage.get("storeAuthInfo");
  }

  async removeStoreAuthInfo(): Promise<any> {
    return await this.storage.remove("storeAuthInfo");
  }

  async setTokenResponse(value: TokenResponse): Promise<any> {
    return await this.storage.set("tokenResponse", value);
  }

  async getTokenResponse(): Promise<TokenResponse> {
    return await this.storage.get("tokenResponse");
  }

  async removeTokenResponse(): Promise<any> {
    return await this.storage.remove("tokenResponse");
  }
  async setReturnUrl(url: string): Promise<void> {
    console.log("Setting return URL:", url);
    return await this.storage.set("returnUrl", url); // Corrected order of parameters
  }

  async getReturnUrl(): Promise<string> {
    return await this.storage.get("returnUrl");
  }
  async clearLocalStorage(): Promise<any> {
    return await this.storage.clear();
  }

  async getCompanyId(): Promise<string> {
    const tokenResponse: TokenResponse = await this.getTokenResponse();
    const operational: Operational = tokenResponse.user as Operational;
    if (operational) {
      return operational.companyId;
    }
  }

  async getStoreId(): Promise<string> {
    const tokenResponse: TokenResponse = await this.getTokenResponse();
    const operational: Operational = tokenResponse.user as Operational;
    if (operational) {
      return operational.storeId;
    }
  }

  async setSignedInEmployee(value: Employee): Promise<any> {
    return await this.storage.set("signedInEmployee", value);
  }

  async getSignedInEmployee(): Promise<Employee> {
    return await this.storage.get("signedInEmployee");
  }

  async removeSignedInEmployee(): Promise<any> {
    return await this.storage.remove("signedInEmployee");
  }
}
